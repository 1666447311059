import { Autocomplete, CardContent, CardHeader, MenuItem, Select, TextField } from '@mui/material'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import 'react-datepicker/dist/react-datepicker.css'
import {
  GiveTerminal,
  useGiveTerminalsListQuery,
  useLazyGiveTerminalsListQuery,
  useLazyGiveTransactionsGetChartsTransactionsStatisticsByTerminalsQuery
} from 'src/store/api/coreApi'

// ** Third Party Imports
import DatePicker from 'react-datepicker'

// ** Styled Components
import { Field, Formik, FormikProps } from 'formik'
import DatePickerWrapper from 'src/@core/styles/libs/react-datepicker'

// ** Custom Components Imports
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import ReactApexcharts from 'src/@core/components/react-apexcharts'
import DonationStatisticsByTerminal from 'src/views/dashboard/DonationStatisticsGroup'

const MDGiveReport = () => {
  const [getFilteredChartData, result] = useLazyGiveTransactionsGetChartsTransactionsStatisticsByTerminalsQuery({})
  const { data, isLoading } = useGiveTerminalsListQuery({ pageSize: 10000 })
  const [getTerminals, { data: terminalData }] = useLazyGiveTerminalsListQuery()
  const [autocompleteSearch, setAutocompleteSearch] = useState<string>('')
  const [autocompleteOptions, setAutocompleteOptions] = useState<GiveTerminal[]>([])
  const userInfo = useSelector((state: any) => state.user)
  const formikRef = useRef<any>()
  const [filteredOption, setFilteredOption] = useState(null)

  const initialValues = {
    dateAfter: null,
    dateBefore: new Date(),
    terminals: [],
    reportType: 'month'
  }

  const onSubmitForm = (values: any) => {
    const finalData = { ...values }
    finalData.dateAfter = finalData.dateAfter?.toISOString().split('T')[0]
    finalData.dateBefore = finalData.dateBefore ? finalData.dateBefore.toISOString().split('T')[0] : undefined
    finalData.terminals = values.terminals.map((o: any) => o.serial_number)
    setFilteredOption(finalData)
    getFilteredChartData(finalData)
  }

  const options = {
    chart: {
      type: 'mixed',
      height: 350,
      animations: {
        enabled: false
      }
    },
    xaxis: {
      type: 'category'
    },
    stroke: {
      width: [0, 2]
    },

    yaxis: [
      {
        title: {
          text: 'Total Donation'
        },
        labels: {
          formatter: function (val: any) {
            return Math.floor(val)
          }
        }
      },
      {
        title: {
          text: 'Average Donation (€)'
        },
        opposite: true,
        labels: {
          formatter: function (val: any) {
            return val.toFixed(2)
          }
        }
      }
    ]
  }

  useEffect(() => {
    if (userInfo && userInfo.client && data && data?.count > 0) {
      getFilteredChartData({ terminals: data.results.map(ter => ter.serial_number).join(','), reportType: 'month' })

      formikRef.current.setFieldValue('terminals', data.results)
    }
  }, [userInfo, data])

  useEffect(() => {
    if (autocompleteSearch.length > 0) {
      getTerminals({ serialNumberIcontains: autocompleteSearch })
    }
  }, [autocompleteSearch])

  useEffect(() => {
    if ((terminalData?.count as number) > 0) {
      setAutocompleteOptions(terminalData?.results as GiveTerminal[])
    }
  }, [terminalData])

  useEffect(() => {
    if ((data?.count as number) > 0) {
      setAutocompleteOptions(data?.results as GiveTerminal[])
    }
  }, [data])

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Formik initialValues={initialValues} onSubmit={onSubmitForm} innerRef={formikRef}>
          {({ values, setFieldValue, submitForm }: FormikProps<any>) => (
            <Card>
              <CardContent sx={{ textAlign: 'center' }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    {!isLoading && (
                      <Field
                        as={Autocomplete}
                        name='terminals'
                        multiple
                        options={autocompleteOptions}
                        onInputChange={(event: any, newInputValue: any) => {
                          setAutocompleteSearch(newInputValue)
                        }}
                        onChange={(event: any, newValue: any | null) => {
                          setFieldValue('terminals', newValue)
                          if (newValue.length > 0 || (newValue.length === 0 && userInfo.client)) {
                            submitForm()
                          }
                        }}
                        getOptionLabel={(option: any) => `${option.name}(${option.serial_number})`}
                        renderInput={(params: any) => <TextField {...params} variant='outlined' label='Terminal' />}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <DatePickerWrapper>
                      <Field
                        name='dateAfter'
                        as={DatePicker}
                        selected={values.dateAfter}
                        showYearDropdown
                        showMonthDropdown
                        dateFormat='dd-MM-yyyy'
                        selectsStart
                        endDate={values.dateBefore}
                        onChange={(newValue: any | null) => {
                          setFieldValue('dateAfter', newValue)
                          submitForm()
                        }}
                        customInput={<TextField label='From Date' fullWidth disabled />}
                      />
                    </DatePickerWrapper>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <DatePickerWrapper>
                      <Field
                        as={DatePicker}
                        selected={values.dateBefore}
                        name='dateBefore'
                        dateFormat='dd-MM-yyyy'
                        showYearDropdown
                        showMonthDropdown
                        selectsEnd
                        startDate={values.dateAfter}
                        endDate={values.dateBefore}
                        minDate={values.dateAfter}
                        placeholderText='DD-MM-YYYY'
                        customInput={<TextField label='Until Date' fullWidth />}
                        onChange={(newValue: any | null) => {
                          setFieldValue('dateBefore', newValue)
                          submitForm()
                        }}
                      />
                    </DatePickerWrapper>
                  </Grid>
                  <Grid item xs={2}>
                    <Field
                      as={Select}
                      name='reportType'
                      label='Type'
                      fullWidth
                      onChange={(e: any) => {
                        setFieldValue('reportType', e.target.value)
                        submitForm()
                      }}
                    >
                      <MenuItem value={'day'}>Per Day</MenuItem>
                      <MenuItem value={'week'}>Per Week</MenuItem>
                      <MenuItem value={'month'}>Per Month</MenuItem>
                      <MenuItem value={'year'}>Per Year</MenuItem>
                    </Field>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )}
        </Formik>
      </Grid>
      {result?.data &&
        Object.entries(result.data).map(([key, value], idx) => (
          <Grid item xs={12} key={`${key}-${idx}`}>
            <Card>
              <CardHeader title={(value as any)?.name} titleTypographyProps={{ variant: 'h5' }} />
              <DonationStatisticsByTerminal filters={filteredOption} />
              <ReactApexcharts type='line' height={300} options={options as any} series={(value as any)?.data} />
            </Card>
          </Grid>
        ))}
    </Grid>
  )
}

export default MDGiveReport
