// ** MUI Imports
/* eslint-disable */

import { Grid } from '@mui/material'
import { useTranslation } from 'next-i18next'
import { useEffect } from 'react'
import { useLazyGiveTransactionsGetTransactionsStatisticsQuery } from 'src/store/api/coreApi'
import DonationStatistics from './DonationStatistics'

const DonationStatisticsByTerminal = ({ filters, serialNumber, client_id }: any) => {
  const [getStatisticsData, { data: statisticsData }] = useLazyGiveTransactionsGetTransactionsStatisticsQuery()
  const { t } = useTranslation()

  useEffect(() => {
    getStatisticsData({ ...filters, terminals: serialNumber, client_id })
  }, [getStatisticsData, filters])
  const frenchLocaleOptions = {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }
  return (
    <Grid container spacing={2} sx={{ padding: 4 }}>
      <Grid item xs={4}>
        <DonationStatistics
          title={t('NumberOfDonations')}
          data={
            statisticsData?.length
              ? //@ts-ignore
                statisticsData?.find(el => el.client_id === client_id)?.count || 0
              : statisticsData?.count || 0
          }
        />
      </Grid>
      <Grid item xs={4}>
        <DonationStatistics
          title={t('AverageCollectedAmount')}
          data={
            statisticsData?.length
              ? //@ts-ignore
                statisticsData
                  //@ts-ignore
                  ?.find(el => el.client_id === client_id)
                  ?.average?.toLocaleString('de-DE', frenchLocaleOptions)
                ? //@ts-ignore
                  //@ts-ignore

                  statisticsData
                    //@ts-ignore

                    ?.find(el => el.client_id === client_id)
                    ?.average?.toLocaleString('de-DE', frenchLocaleOptions)
                : 0
              : //@ts-ignore
                statisticsData?.average?.toLocaleString('de-DE', frenchLocaleOptions)
          }
        />
      </Grid>
      <Grid item xs={4}>
        <DonationStatistics
          title={t('CollectedAmount')}
          data={
            statisticsData?.length
              ? //@ts-ignore
                statisticsData
                  //@ts-ignore
                  ?.find(el => el.client_id === client_id)
                  ?.total?.toLocaleString('de-DE', frenchLocaleOptions)
                ? //@ts-ignore
                  statisticsData
                    //@ts-ignore

                    ?.find(el => el.client_id === client_id)
                    ?.total?.toLocaleString('de-DE', frenchLocaleOptions)
                : 0
              : //@ts-ignore

                statisticsData?.total?.toLocaleString('de-DE', frenchLocaleOptions)
          }
        />
      </Grid>
    </Grid>
  )
}

export default DonationStatisticsByTerminal
