// ** MUI Imports
import { Skeleton } from '@mui/material'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Typography from '@mui/material/Typography'

// ** Icons Imports

// ** Third Party Imports

// ** Custom Components Imports

const DonationStatistics = ({ title, data }: any) => {
  // ** Hook

  return (
    <>
      {JSON.stringify(data) ? (
        <Card>
          <CardHeader
            title={title}
            titleTypographyProps={{
              sx: { lineHeight: '2rem !important', letterSpacing: '0.15px !important' }
            }}
          />
          <CardContent>
            <Box sx={{ mb: 7, display: 'flex', alignItems: 'center' }}>
              <Typography variant='h3' sx={{ mr: 4, width: '100%' }} align='center'>
                {data}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      ) : (
        <Skeleton variant='rectangular' height={180} animation={'wave'} />
      )}
    </>
  )
}

export default DonationStatistics
